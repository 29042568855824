import type { FC } from 'react';
import { useState } from 'react';
import type { TAmenitiesData } from './Policies.types';
import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';

export type TPoliciesList = {
  items: TAmenitiesData[];
};

const PoliciesList: FC<TPoliciesList> = function ({ items }) {
  const defaultTab = items.length ? getTabId(0) : '';
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  if (!items.length) return null;

  return (
    <Tabs
      changeWithArrow
      className="container h-full"
      defaultActive={defaultTab}
      onTabChange={(id) => {
        setSelectedTab(id || '');
      }}
    >
      <TabList className="border-b">
        {items.map((tab, index) => {
          const tabId = getTabId(index);
          return (
            <TabListButton
              activeClassName="border-b-primary brand-es:border-b-secondary"
              id={tabId}
              key={tabId}
            >
              <div
                className={cx(
                  'text-text flex min-w-[120px] flex-col items-center brand-es:text-secondary',
                  {
                    'hover:text-brand brand-es:hover:text-primary': tabId !== selectedTab,
                  }
                )}
              >
                <Icon
                  className={cx({ hidden: tabId === selectedTab })}
                  name={tab.icon}
                  size="2xl"
                />
                <Icon
                  className={cx({ hidden: tabId !== selectedTab })}
                  name={tab.icon}
                  size="2xl"
                  variant="solid"
                />
                <p
                  className={cx('stroke-text-inverse py-4 text-lg brand-es:text-text', {
                    'font-bold': tabId === selectedTab,
                    'font-medium': tabId !== selectedTab,
                  })}
                >
                  {tab.name}
                </p>
              </div>
            </TabListButton>
          );
        })}
      </TabList>
      <TabPanels>
        {items.map((tab, index) => {
          const tabId = getTabId(index);
          return (
            <TabPanel id={tabId} key={tabId} data-testid={`test-tab-panel-${tab.name}`}>
              <div className="bg-bg-alt mb-4 w-full px-8 py-6">{tab.description}</div>
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

function getTabId(index: number) {
  return `policies-tab-${index}`;
}

export default PoliciesList;
